import sharedForm from "@/components/shared/form"
import api from "@/api/users"
export default {

    data() {
        return {
            message: "Identifiez-vous",
            snackbar: false,
            timeout: 3000,
            errors: [],
            from: {},
            fields: [{ type: 'email', label: 'Votre identifiant', value: '', rules: 'required|email', placeholder: 'Identifiant', name: 'email' }, { type: 'password', label: 'Votre Mot de passe', value: '', rules: 'required', placeholder: 'Mot de passe', name: 'password' }],
            btn: [{ label: "Se connecter", method: "connect", type: "btn" }, { label: "Mot de passe perdu ?", method: "forgetPassword", type: "link" }],
            // datas:[]
        };
    },

    components: {
        sharedForm
    },
    methods: {
        login: function () {
            let data = {};
            this.fields.forEach(field => {
                data[field.name] = field.value;
            });
            api.login(data).then((res) => {
                this.$store.commit("infosUser", res.data.data);
                //Check role
                if(this.from.name == 'call'){
                    this.$router.push({ name: "call",query:this.from.query });
                }
                if (this.$store.state.user.Role.role === "administrateur" ) {
                    this.$router.push({ name: "dashboard" })
                } else if (this.$store.state.user.Role.role === "CCVE" ){
                    this.$router.push({ name: "export_ccve" })
                } else {
                   
                    this.$router.push({ name: "call" });
                }
            })
                .catch((error) => {
                    if (
                        401 === error.response.status ||
                        400 === error.response.status ||
                        500 === error.response.status
                    ) {
                        let msg = "IDENTIFIANT OU MOT DE PASSE INCORRECT"
                        this.errors.push(msg)
                        this.snackbar = true
                        // erreur sur le login depuis le back
                        // this.err.splice(0, 1);
                        // this.err.push(error.response.data.message);
                    } else {
                        // console.log('error not defined', error)
                    }
                });

            console.log(data)
            console.log('in login methods', this.fields, 'data refactor', data)
        },
        reset: function () {
            console.log(' in reset methods', this.fields)
        },

    }, created() {
        console.log(this.$router,'router')


    }, beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from
          console.log('from before route',from.name,from,vm)
        })
      },
}
